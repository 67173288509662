import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
// import RichText from '~3-data-components/RichText';

const pageSectionEg = `import PageSection from '~2-components/PageSection/PageSection';
//For HardCoded Data Don't Use RichText, just put directly the content. I.e: 
<h2>Example HTML being rendered out</h2>
<p>Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Etiam porta sem malesuada magna mollis euismod. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam.</p>
`;

const SgRichText = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Rich Text</h1>
                    <h2>Kontent data</h2>
                    <p>Handles all the parsing of CMS data and replacing references to InlineAssets, LinkedItems, and InlineLinks with their proper components.</p>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={pageSectionEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <h2>Example HTML being rendered out</h2>
                    <p>Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Etiam porta sem malesuada magna mollis euismod. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam.</p>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgRichText;
